<template>
  <b-card-code
    title="Agregar una nueva Marca"
  >
    <b-card-body>
      <FormCreateComponent
        root="brands"
      />
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BCardBody } from 'bootstrap-vue'
import FormCreateComponent from './components/FormCreateComponent.vue'

export default {
  name: 'BrandCreatePage',
  components: {
    BCardCode,
    BCardBody,
    FormCreateComponent,
  },
}
</script>
